<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project'}">项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>环境变量</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div class="env-params-headers" slot="header">
        <div>
          <el-button round @click="goHome">返回</el-button>
          <el-button type="warning" icon="el-icon-caret-right" @click="handleAdd">新增变量</el-button>
        </div>
        <el-form :inline="true" :model="envParamsForm" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="envParamsForm.envParamsName" placeholder="输入环境名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="编号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="params_key"
          label="参数名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="params_value"
          label="参数值"
          width="180">
        </el-table-column>
        <el-table-column
          prop="env_id"
          label="所属环境"
          width="180">
        </el-table-column>
        <el-table-column
          prop="description"
          label="环境描述">
        </el-table-column>
        <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button round type="warning" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button round type="danger" @click="handleDel(scope.row)">删除</el-button>
          <!-- <el-button round type="info" @click="handleDis(scope.row)">{{ scope.row.status === '1' ? '禁用' : '启用' }}</el-button> -->
        </template>
      </el-table-column>
      </el-table>
    </el-card>

    <!-- 环境变量列表分页 -->
    <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="pageInfo.currentPage"
    :page-sizes="[1, 10, 30, 50]"
    :page-size="pageInfo.size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增环境变量 dialog -->
    <el-dialog title="接口信息" :visible.sync="dialogFormVisible" width="55%">
      <el-form :model="dialogEnvParamsForm" ref="dialogEnvParamsFormRef">
        <el-form-item  v-if="!isAdd" label="编号" prop="id" :label-width="formLabelWidth">
          <el-input v-model="dialogEnvParamsForm.id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="参数名称" :label-width="formLabelWidth">
          <el-input v-model="dialogEnvParamsForm.params_key" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="参数值" :label-width="formLabelWidth">
          <el-input v-model="dialogEnvParamsForm.params_value" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述信息" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="dialogEnvParamsForm.description" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属环境" :label-width="formLabelWidth">
          <el-input v-model="dialogEnvParamsForm.env_id" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEdit">编 辑</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { QueryEnvParamsList, AddEnvParams, EditEnvParams, DelEnvParams, SearchEnvParams } from '@/services/envParams.js'
export default {
  name: 'EnvParams',
  data () {
    return {
      envParamsForm: {
        envParamsName: ''
      },
      tableData: [],
      pageInfo: {
        size: 10,
        currentPage: 1,
        total_count: null
      },
      isAdd: true,
      dialogFormVisible: false,
      dialogEnvParamsForm: {
        id: '',
        env_id: '',
        params_key: '',
        params_value: '',
        description: ''
      },
      formLabelWidth: '120px'
    }
  },
  created () {
    this.loadEnvParamsList()
  },
  methods: {
    async loadEnvParamsList () {
      const QueryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await QueryEnvParamsList(QueryObj)
      console.log('res', '<--获取环境变量信息')
      if (res.status === 1) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    goHome () {
      this.$router.go(-1)
    },
    async handleSearch () {
      const SearchObj = {
        query_type: 'search_env_params',
        params_key: this.envParamsForm.envParamsName
      }
      const { data: res } = await SearchEnvParams(SearchObj)
      if (res.status === 1) {
        this.tableData = res.data
      }
    },
    handleAdd () {
      this.isAdd = true
      this.dialogFormVisible = true
    },
    handleEdit (rowData) {
      this.isAdd = false
      this.dialogFormVisible = true
      this.dialogEnvParamsForm.id = rowData.id
      this.dialogEnvParamsForm.env_id = rowData.env_id
      this.dialogEnvParamsForm.params_key = rowData.params_key
      this.dialogEnvParamsForm.params_value = rowData.params_value
      this.dialogEnvParamsForm.description = rowData.description
    },
    handleDel (rowData) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const DelObj = {
          id: rowData.id,
          env_id: rowData.env_id
        }
        const { data: res } = await DelEnvParams(DelObj)
        if (res.status === 1) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.loadEnvParamsList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      });
    },
    // 环境信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadEnvParamsList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadEnvParamsList()
      console.log(`当前页: ${val}`)
    },
    async processAdd () {
      try {
        const AddObj = {
          env_id: this.dialogEnvParamsForm.env_id,
          params_key: this.dialogEnvParamsForm.params_key,
          params_value: this.dialogEnvParamsForm.params_value,
          description: this.dialogEnvParamsForm.description
        }
        const { data: res } = await AddEnvParams(AddObj)
        if (res.status === 1) {
          this.dialogFormVisible = false
          this.loadEnvParamsList()
          this.$message.success('添加环境变量成功')
          this.dialogEnvParamsForm.env_id = ''
          this.dialogEnvParamsForm.params_key = ''
          this.dialogEnvParamsForm.params_value = ''
          this.dialogEnvParamsForm.description = ''
        }
      } catch (error) {
        this.$message.warning('校验未通过')
      }
    },
    async processEdit () {
      try {
        const EditObj = {
          id: this.dialogEnvParamsForm.id,
          env_id: this.dialogEnvParamsForm.env_id,
          params_key: this.dialogEnvParamsForm.params_key,
          params_value: this.dialogEnvParamsForm.params_value,
          description: this.dialogEnvParamsForm.description
        }
        const { data: res } = await EditEnvParams(EditObj)
        if (res.status === 1) {
          this.dialogFormVisible = false
          this.loadEnvParamsList()
          this.$message.success('修改变量信息成功')
          this.dialogEnvParamsForm.env_id = ''
          this.dialogEnvParamsForm.params_key = ''
          this.dialogEnvParamsForm.params_value = ''
          this.dialogEnvParamsForm.description = ''
        }
      } catch (error) {
        this.$message.warning('校验未通过')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.env-params-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demo-form-inline {
  height: 40px;
}
.el-pagination {
  padding: 0px 0;
}
</style>
