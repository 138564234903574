<template>
  <div class="suite">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="自动化测试" name="first">
        <router-view></router-view>
      </el-tab-pane>
      <el-tab-pane label="环境信息" name="second">
        <Env></Env>
      </el-tab-pane>
      <el-tab-pane label="环境变量" name="fourth">
        <Env-params></Env-params>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Env from './components/env.vue'
import EnvParams from './components/envParams.vue';
export default {
  name: 'Suite',
  components: { Env, EnvParams },
  data () {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style>

</style>
