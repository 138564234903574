import request from '@/utils/request.js'
import { urlPath } from './apiBaseInfo'

// 查询环境列表信息
export const QueryEnvList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'env',
    params: {
      ...data
    }
  })
}

// 添加环境信息
export const AddEnv = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'env',
    data
  })
}

// 编辑环境信息
export const EditEnv = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'env',
    data
  })
}

// 删除环境信息
export const DelEnv = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'env',
    data
  })
}

// 按照名称搜索环境信息
export const SearchEnv = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'env',
    params: {
      ...data
    }
  })
}
