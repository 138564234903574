import request from '@/utils/request.js'
import { urlPath } from './apiBaseInfo'

// 查询环境变量信息
export const QueryEnvParamsList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'env/params',
    params: {
      ...data
    }
  })
}

// 新增环境变量信息
export const AddEnvParams = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'env/params',
    data
  })
}

// 编辑环境变量信息
export const EditEnvParams = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'env/params',
    data
  })
}

// 删除环境变量信息
export const DelEnvParams = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'env/params',
    data
  })
}

// 依据名称搜索环境变量信息
export const SearchEnvParams = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'env/params',
    params: {
      ...data
    }
  })
}
