<template>
  <div class="env">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project'}">项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>环境信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div class="env-header" slot="header">
        <div>
          <el-button round icon="el-icon-d-arrow-left" @click="goHome">返回</el-button>
          <el-button type="warning" icon="el-icon-caret-right" @click="handleAdd">新增环境</el-button>
        </div>
        <el-form :inline="true" :model="envForm" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="envForm.envName" placeholder="输入环境名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="编号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="env_name"
          label="环境名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="description"
          label="环境描述">
        </el-table-column>
        <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button round type="warning" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button round type="danger" @click="handleDel(scope.row)">删除</el-button>
          <!-- <el-button round type="info" @click="handleDis(scope.row)">{{ scope.row.status === '1' ? '禁用' : '启用' }}</el-button> -->
        </template>
      </el-table-column>
      </el-table>
    </el-card>

    <!-- 环境列表分页 -->
    <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="pageInfo.currentPage"
    :page-sizes="[1, 10, 30, 50]"
    :page-size="pageInfo.size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增测试用例 dialog -->
    <el-dialog title="接口信息" :visible.sync="dialogFormVisible" width="55%">
      <el-form :model="dialogEnvForm" ref="dialogEnvFormRef">
        <el-form-item  v-if="!isAdd" label="编号" prop="id" :label-width="formLabelWidth">
          <el-input v-model="dialogEnvForm.id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="环境名称" :label-width="formLabelWidth">
          <el-input v-model="dialogEnvForm.env_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="环境描述" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="dialogEnvForm.description" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属项目" :label-width="formLabelWidth">
          <el-input v-model="dialogEnvForm.project_id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="operation" :label-width="formLabelWidth">
          <el-input v-model="dialogEnvForm.operation" autocomplete="off" disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEdit">编 辑</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { QueryEnvList, AddEnv, EditEnv, DelEnv, SearchEnv } from '@/services/env.js'
export default {
  name: 'Env',
  data () {
    return {
      envForm: {
        envName: ''
      },
      pageInfo: {
        size: 10,
        currentPage: 1,
        total_count: null
      },
      tableData: [],
      isAdd: true,
      dialogFormVisible: false,
      dialogEnvForm: {
        id: '1',
        env_name: '',
        description: '',
        project_id: this.$store.state.projectId,
        operation: 'add'
      },
      formLabelWidth: '120px'
    }
  },
  created () {
    this.loadEnvList()
  },
  methods: {
    // 查询环境列表
    async loadEnvList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await QueryEnvList(queryObj)
      console.log(res, '<--环境列表信息')
      if (res.status === 1) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    goHome () {
      this.$router.go(-1)
    },
    async handleSearch () {
      const SearchObj = {
        query_type: 'search_env',
        env_name: this.envForm.envName
      }
      const { data: res } = await SearchEnv(SearchObj)
      if (res.status === 1) {
        this.tableData = res.data
      }
    },
    handleAdd () {
      this.isAdd = true
      this.dialogFormVisible = true
    },
    handleEdit (rowData) {
      this.isAdd = false
      this.dialogFormVisible = true
      this.dialogEnvForm.id = rowData.id
      this.dialogEnvForm.env_name = rowData.env_name
      this.dialogEnvForm.description = rowData.description
      this.dialogEnvForm.operation = rowData.operation
    },
    handleDel (rowData) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const DelObj = {
          project_id: this.$store.state.projectId,
          env_id: rowData.id
        }
        const { data: res } = await DelEnv(DelObj)
        if (res.status === 1) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.loadEnvList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 环境信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadEnvList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadEnvList()
      console.log(`当前页: ${val}`)
    },
    async processAdd () {
      try {
        const AddObj = {
          project_id: this.dialogEnvForm.project_id,
          env_name: this.dialogEnvForm.env_name,
          description: this.dialogEnvForm.description,
          operation: this.dialogEnvForm.operation
        }
        const { data: res } = await AddEnv(AddObj)
        console.log(res, '<--创建环境返回信息')
        if (res.status === 1) {
          this.dialogFormVisible = false
          this.loadEnvList()
        }
      } catch (error) {
        this.$message.error('信息校验未通过')
      }
    },
    async processEdit () {
      try {
        this.isAdd = false
        const EditObj = {
          id: this.dialogEnvForm.id,
          env_name: this.dialogEnvForm.env_name,
          description: this.dialogEnvForm.description,
          project_id: this.dialogEnvForm.project_id
        }
        const { data: res } = await EditEnv(EditObj)
        if (res.status === 1) {
          this.dialogEnvForm.id = ''
          this.dialogEnvForm.env_name = ''
          this.dialogEnvForm.description = ''
          this.dialogFormVisible = false
          this.loadEnvList()
        }
      } catch (error) {
        this.$message.warning('信息校验未通过')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.env-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demo-form-inline {
    height: 40px;
}
.el-pagination {
  padding: 0px 0;
}

</style>
